import interact from 'interactjs'

const draggable = {
  beforeMount (el, { value }) {
    const element = interact(el)
    element.draggable({
      inertia: true,
      autoScroll: true,
      onstart: (event) => {
        // We put the start item inside, so we will recover it from drop event
        event.target.value = value
        el.classList.add('dragging')
      },
      onmove: (event) => {
        // keep the dragged position in the data-x/data-y attributes
        const x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx
        const y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy
        // translate the element
        event.target.style.webkitTransform = 'translate(' + x + 'px, ' + y + 'px)'
        event.target.style.transform = event.target.style.webkitTransform
        event.target.style.zIndex = 20000
        // update the posiion attributes
        event.target.setAttribute('data-x', x)
        event.target.setAttribute('data-y', y)
      },
      onend: (event) => {
        event.target.style.webkitTransform = 'translate(0px, 0px)'
        event.target.style.transform = event.target.style.webkitTransform
        event.target.setAttribute('data-x', 0)
        event.target.setAttribute('data-y', 0)
        event.target.style.zIndex = 1
        process.nextTick(() => {
          el.classList.remove('dragging')
        })
      }
    })
  }
}

export default draggable

