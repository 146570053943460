import FpApp from './FpApp'
import FpAside from './FpAside'
import FpHeader from './Header/FpHeader'
import FpSplashError from './FpSplashError'
import FpTitle from './FpTitle'
import FpSidebar from './FpSidebar'

export { FpApp, FpAside, FpHeader, FpSplashError, FpTitle, FpSidebar }
export default { FpApp, FpAside, FpHeader, FpSplashError, FpTitle, FpSidebar }

