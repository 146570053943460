import Config from '@/shared/Config'
import API from '@/shared/plugins/Api/API'
import qs from 'qs'
import urljoin from 'url-join'
import ACLS from './ACLS'
import Applications from './Applications.js'
import AuthenticationProviders from './AuthenticationProviders.js'
import Configuration from './Configuration.js'
import Groups from './Groups.js'
import Mails from './Mails'
import Metrics from './Metrics.js'
import Roles from './Roles.js'
import ServiceAccounts from './ServiceAccounts.js'
import Users from './Users.js'

class IAM extends API {
  constructor (id) {
    super(id)
    this.metrics = new Metrics(id)
    this.authentication_providers = new AuthenticationProviders(id)
    this.roles = new Roles(id)
    this.applications = new Applications(id)
    this.users = new Users(id)
    this.groups = new Groups(id)
    this.configuration = new Configuration(id)
    this.service_accounts = new ServiceAccounts(id)
    this.mails = new Mails(id)
    this.acls = new ACLS(id)
    this.cacheAcls = {}
  }

  async getCacheKey (key) {
    if (!key) return null
    const config = await Config()
    return `${config.PROJECT_ID}::${key}`
  }

  async can (Service, Resource, Action, object) {
    const keyCache = await this.getCacheKey(!object && `${Service}::${Resource}::${Action}`)
    let data = null
    if (keyCache) {
      this.cacheAcls[keyCache] = this.cacheAcls[keyCache] || this.request({
        method: 'POST',
        url: 'v4/opa/query',
        data: {
          Service,
          Resource,
          Action
        }
      })
      data = await this.cacheAcls[keyCache]
    } else {
      data = await this.request({
        method: 'POST',
        url: 'v4/opa/query',
        data: {
          Service,
          Resource,
          Action,
          Object: object
        }
      })
    }

    return data.allow
  }

  async reply (token) {
    return this.request({
      url: 'v4/reply',
      params: {
        redirect_uri: 'false',
        token_mode: 'cookie',
        authentication_provider: 'forepaas'
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  async checksession (noReconnect = false) {
    return this.request({
      url: 'v4/checksession',
      retries: 0,
      noReconnect: noReconnect
    })
      .catch(err => {
        if (err.response?.status === 401 || err.response?.status === 403) return null
        throw err
      })
  }

  async authenticationProvidersStore () {
    return this.request({
      url: 'v4/store/authentication_providers'
    })
  }

  async applicationsStore () {
    return this.request({
      url: 'v4/store/applications'
    })
  }

  async preferences (clientId = null) {
    const config = await Config()
    return this.request({
      url: `v4/preferences/${clientId || config.APP_ID}`
    })
  }

  async login ({ login, email, password }) {
    const config = await Config()
    return this.request({
      url: 'v4/login',
      method: 'POST',
      retries: 0,
      data: {
        auth_mode: 'login_password',
        login,
        email,
        password,
        app_id: config.APP_ID
      }
    })
  }

  async passwordReset ({ email }) {
    return this.request({
      url: 'v4/sendRenewRequest',
      method: 'POST',
      data: {
        email
      }
    })
  }

  async passwordRenew (token, newPassword) {
    return this.request({
      url: 'v4/passwordReset',
      method: 'POST',
      retries: 0,
      data: {
        password: newPassword,
        token
      }
    })
  }

  async unblock (id) {
    return this.request({
      method: 'PUT',
      url: `v4/users/${id}/unblock`
    })
  }

  async loginRedirect (loginMode, forceAuth = false, clientId = null) {
    const config = await Config()
    const queryString = qs.stringify({
      authentication_provider: loginMode._id,
      app_id: clientId || config.APP_ID,
      redirect_uri: window.location.href,
      token_mode: 'cookie',
      force_auth: forceAuth,
      organization_id: config.ORGANIZATION_MODE ? config.ORGANIZATION_ID : undefined
    })
    const loginUrl = urljoin(config.IAM, '/v4/login', `?${queryString}`)
    window.location.href = loginUrl
  }

  // Send code to the user. For now it is used for only SMS
  async sendMFACode (type) {
    return this.request({
      method: 'post',
      retries: 0,
      url: `v4/mfa/send/${type}`,
      headers: {
        'Organization-Id': 'king'
      }
    })
  }

  // Used in the login page
  async validateMFACode ({ type, otp }) {
    return this.request({
      method: 'post',
      retries: 0,
      url: `v4/mfa/validate/${type}`,
      data: {
        otp
      },
      headers: {
        'Organization-Id': 'king'
      }
    })
  }

  async signup ({ email, password, name, tc_accepted, redirect_uri, tokenJoin, organizationId }) {
    return this.request({
      method: 'post',
      url: 'v4/signup',
      data: {
        email,
        password,
        name,
        tc_accepted,
        redirect_uri,
        tokenJoin,
        organizationId
      }
    })
  }

  // Check if the email is invited
  async canSignupEmail (email) {
    const signupRequest = await this.request({
      originalError: true,
      method: 'POST',
      url: 'v4/signup',
      data: {
        email
      }
    })

    if (signupRequest?.response?.data?.status === 400 && signupRequest?.response?.data?.error === 'MISSING_FIELD') return true
    return signupRequest?.response?.data?.error
  }

  async logout () {
    return this.request({
      method: 'delete',
      url: 'v4/logout'
    })
      .catch(err => {
        if (err.response.status === 400) return null
        throw err
      })
  }
}

export default IAM
