import { API, APIObject } from '@/shared/plugins/Api/API'
import Store from '@/shared/store'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import { environmentModel } from './Environments'
import Vue from 'vue'
// import mockWorkflows from './mock/workflows'

class Workflow extends APIObject {
  static list
  constructor (options) {
    // Init
    super('DPE', options)

    // Default variables
    this.__lastStart = null
    this.type = 'workflow'
    this.tags = this.tags || {}
    this.modules = this.modules || []
    this.description = this.description || ''
    this.tags = typeof (this.tags) === 'object' ? this.tags : {}
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    this.params = typeof (this.params) === 'object' ? this.params : {}
    this.params.timeout = this.params.timeout !== undefined ? this.params.timeout : '2h'
    this.display_name = this.display_name || ''
    this.stages = Array.isArray(this.stages) ? this.stages.map(this.formatStage) : []
    this.starting = this.starting || false

    // Envs
    this.environment = this.environment || {}
    switch (typeof (this.environment)) {
      case 'string':
        break
      case 'object':
        break
      default:
        this.environment = {}
    }

    if (typeof (this.environment) === 'object') {
      environmentModel(this.environment)
    }
  }

  formatStage (stage) {
    stage.disable = stage.disable || false
    stage.actions = stage.actions.map(action => {
      action.disable = action.disable || false
      return action
    })
    return stage
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__lastStart',
      '__socketId',
      'tags.table',
      'tags.workflow_builder',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name',
      'starting',
      'build',
      'fppm',
      'path',
      'level',
      'cron',
      'type'
    ])
    return obj
  }

  clone () {
    return new Workflow(_cloneDeep(this.toJSON()))
  }

  async refresh () {
    const workflow = await this.request({
      method: 'GET',
      url: `v3/workflow/${this._id}`
    })
    this.assign(workflow)
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v3/workflows'
    })
  }

  async save () {
    // Tracking
    const environment = typeof (this.environment) === 'string' ? Store.getters.DPE_ENVIRONMENT_BY_ID(this.environment) || {} : this.environment
    let actions = 0
    this.stages.forEach(s => { actions += s.actions.length })
    Vue.$analytics.track('Edit workflow', {
      workflow_id: this._id,
      log_level: environment.log_level,
      auto_flushall: environment.params?.auto_flushall,
      date_limits: this.params?.days ? 'relative' : this.params?.dateMin ? 'absolute' : 'disabled',
      perimeter_option: environment.params?.perimeter?.type ? Vue.i18n.translate(`preferences.editor.type.${environment.params?.perimeter?.type}`) : 'None',
      segmentation_option: environment.params?.segmentation?.type ? Vue.i18n.translate(`preferences.editor.type.${environment.params?.segmentation?.type}`) : 'None',
      execution_mode: environment.type === 'deployment' ? 'always-ready' : 'serverless',
      resources_type: environment.fpu?.type,
      resources_allocated: environment.fpu?.size * environment.fpu?.instances,
      nb_of_instances: environment.fpu?.instances,
      nb_of_stages: this.stages.length,
      nb_of_actions: actions
    })

    return super.save({
      method: 'PUT',
      url: `v3/workflows/${this._id}`
    })
  }

  async remove () {
    Vue.$analytics.track('Delete workflow', {
      workflow_id: this._id
    })

    return this.request({
      method: 'DELETE',
      url: `v3/workflows/${this._id}`
    })
  }

  async install () {
    return this.request({
      method: 'PUT',
      url: `v3/workflows/${this._id}/install`
    })
  }

  async start () {
    try {
      this.starting = true
      Store.commit('SET_OPEN_CONSOLE', true)
      await this.request({
        method: 'POST',
        url: `v3/workflows/${this._id}/start`
      })

      // this.__lastStart = response.headers.date
    } catch (err) {
      // We handle 409, cause it should push the request into "started state"
      if (err.status !== 409) {
        throw err
      }
      // this.__lastStart = err.date
    } finally {
      this.starting = false
    }
  }

  async stop () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v3/workflows/${this._id}/stop`
    })
  }

  async stopBuild () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v3/workflows/${this._id}/build/stop`
    })
  }

  async stopInstall () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v3/workflows/${this._id}/install`
    })
  }

  get repositoryOverride () {
    let override = this.environment?.repository_version_override
    // If the override comes from an assigned environment
    if (typeof this.environment === 'string' && !this.environment?.repository_version_override) {
      const environment = Store.getters.DPE_ENVIRONMENT_BY_ID(this.environment)
      override = environment?.repository_version_override
    }
    return override === 'disabled' ? false : override
  }
}

class Workflows extends API {
  list (options = {}) {
    const output = (workflows) => {
      return workflows.map(workflow => {
        try {
          return new Workflow(workflow)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    }

    // Uncomment to mock the workflows listing
    // return output(mockWorkflows)

    return this.paginateCursor({
      method: 'get',
      url: 'v3/workflows',
      onProgress (workflows) {
        if (options?.onProgress) options.onProgress(output(workflows))
      },
      params: {
        full: true
      }
    }).then(workflows => {
      if (options?.executeLast) options.executeLast()
      return output(workflows)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Workflow(item)
  }

  // Get one workflow by workflow._id
  async detail (wfId) {
    const workflow = await this.request({
      method: 'GET',
      url: `v3/workflows/${wfId}`
    })
    return new Workflow(workflow)
  }
}

export {
  Workflow,
  Workflows
}

export default Workflows
