import Sortable from 'sortablejs'

const sortable = {
  beforeMount (el, { value }) {
    Sortable.create(el, value)
  },
  updated (el, { value }) {
    Sortable.create(el, value)
  }
}

export default sortable
