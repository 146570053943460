import moment from 'moment-timezone'

const Date = function (utcDate, from = 'UTC', to = 'locale', format = null) {
  /* istanbul ignore next */
  if (from === 'locale') from = moment.tz.guess()
  /* istanbul ignore next */
  if (to === 'locale') to = moment.tz.guess()
  if (format) return moment.tz(utcDate, from).tz(to).format(format)
  return moment.tz(utcDate, from).tz(to)
}

export default Date
