import FpuiModal from './Modal'
import FpuiModalConfirm from './ModalConfirm'
import FpuiModalBody from './ModalBody'
import FpuiModalHeader from './ModalHeader'
import FpuiModalFooter from './ModalFooter'
import FpuiModalMessage from './ModalMessage'
import Confirm from './Confirm'
import FpuiModalSearch from './ModalSearch'

export { FpuiModal, FpuiModalBody, FpuiModalHeader, FpuiModalFooter, FpuiModalConfirm, Confirm, FpuiModalMessage, FpuiModalSearch }
export default { FpuiModal, FpuiModalBody, FpuiModalFooter, FpuiModalHeader, FpuiModalConfirm, Confirm, FpuiModalMessage, FpuiModalSearch }

