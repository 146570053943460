import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import merge from 'lodash/merge'
import sumBy from 'lodash/sumBy'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

import FpDimensions from './LibFromApp/FpDimensions'
import EchartsResult from './LibFromApp/EchartsResult'
import ecStat from 'echarts-stat'
import Vue from 'vue'

class EchartFormatter {
  constructor (forepaas) {
    merge(this, forepaas.options)
    this.forepaas = cloneDeep(forepaas)
    this.forepaas.options.scatterX = this.forepaas.options.xAxisDetails || {}
    this.forepaas.options.scatterY = this.forepaas.options.yAxisDetails || {}

    this.generateAxis()
    this.generateSeries()
    this.generateTooltip()
    this.legend = this.legend || {}
    this.legend.data = this.legend.data || this.generateLegend()
    this.removeLegendCompletly()

    if (this.forepaas.options.series.type === 'pie') {
      this.tooltip.trigger = 'item'
      this.itemStyle = {
        shadowBlur: 0,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0)'
      }
      if (this.series.length > 1) this.series = this.series[0]
    }

    if (this.forepaas.options.series.type === 'scatter') {
      this.xAxis = { ...this.xAxis, scale: true, nameTextStyle: { color: '#97A7B7' } }
      this.yAxis = { ...this.yAxis, scale: true, nameTextStyle: { color: '#97A7B7', padding: [0, 0, 4, -50] } }
    }

    if (this.forepaas.options.series.type === 'line') {
      this.tooltip = {
        ...this.tooltip,
        trigger: 'axis',
        backgroundColor: '#fff',
        textStyle: {
          ...this.tooltip.textStyle,
          color: '#000'
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#fff',
            color: '#000000'
          }
        }
      }
    }

    delete this.forepaas
    return this
  }

  addTrendline () {
    const myRegression = ecStat.regression('linear', this.series[0].data.map(d => d.value))
    myRegression.points.sort(function (a, b) {
      return a[0] - b[0]
    })

    this.series.push({
      name: 'line',
      type: 'line',
      showSymbol: false,
      data: myRegression.points,
      color: this.color[0],
      lineStyle: {
        type: 'dashed'
      },
      markPoint: {
        itemStyle: {
          color: 'transparent'
        },
        label: {
          show: true,
          position: 'left',
          formatter: myRegression.expression,
          fontSize: 14
        },
        data: [{
          coord: myRegression.points[myRegression.points.length - 1]
        }]
      }
    })
  }

  generateLegend () {
    return this.series.map((serie) => serie.name)
  }

  generateAxis () {
    this.forepaas.axis = {}
    if (has(this.forepaas, 'query_params.scale.axis')) {
      this.forepaas.axis = this.forepaas.query_params.scale.axis
    }

    this.forepaas.axis.x = (
      this.forepaas.options?.xAxis?.attributes ||
      this.forepaas.axis.x ||
      (this.forepaas.query_params.scale ? this.forepaas.query_params.scale.fields : null) ||
      []
    )

    this.forepaas.axis.y = this.forepaas.options?.xAxis?.attributes && this.forepaas.options?.xAxis?.attributes.length ? this.forepaas.query_params.scale.fields.filter(scale => !this.forepaas.options?.xAxis?.attributes.includes(scale)) : this.forepaas.axis.y || []
    if ((!this.forepaas.query_params.scale || !this.forepaas.query_params.scale.axis) && (!this.forepaas.options?.xAxis?.attributes || !this.forepaas.options?.xAxis?.attributes.length)) {
      this.forepaas.axis = {
        x: this.forepaas.query_params.scale?.fields || [],
        y: []
      }
    } else {
      this.forepaas.axis = {
        x: this.forepaas.axis.x || [],
        y: this.forepaas.axis.y || []
      }
    }

    this.forepaas.axis.x = this.forepaas.axis.x.filter((dimension) => {
      return this.forepaas.query_params.scale?.fields.indexOf(dimension.toString()) !== -1
    })
    this.forepaas.axis.y = this.forepaas.axis.y.filter((dimension) => {
      return this.forepaas.query_params.scale?.fields.indexOf(dimension.toString()) !== -1
    })
    this.forepaas.axis.x = new FpDimensions(this.forepaas.axis.x)
    this.forepaas.axis.y = new FpDimensions(this.forepaas.axis.y)
    this.xAxis = merge({}, this.forepaas.options.xAxis, this.xAxis)
  }

  generateSeries () {
    if (!this.forepaas.results) return
    this.generateResult()
    this.sortResult()
    const seriesOptions = this.series || []
    this.series = []
    // Loop always done only once since yKey equals {}
    Object.keys(this.forepaas.sorted).forEach((yKey) => {
      for (const data in this.forepaas.query_params.data?.fields) {
        this.forepaas.query_params.data?.fields[data].forEach((computeMode) => {
          this.series.push({
            forepaas: {
              scales: JSON.parse(yKey),
              data: data,
              computeMode,
              evol: 0,
              evolScale: this.getEvol(),
              yKey: yKey
            }
          })
          if (this.getEvol()) {
            this.series.push({
              forepaas: {
                scales: JSON.parse(yKey),
                data: data,
                computeMode,
                evol: 1,
                evolScale: this.getEvol(),
                yKey: yKey
              }
            })
          }
        })
      }
    })
    this.series = this.series
      .map((serie, i) => {
        if (!Array.isArray(seriesOptions)) { Object.assign(serie, seriesOptions) }
        if (seriesOptions[i]) {
          Object.assign(serie, seriesOptions[i])
        }
        return serie
      })
      .map(this.formatSerie.bind(this))

    if (this.forepaas.options.trendline) {
      this.addTrendline()
    }
  }

  // color = ['#39d2f5', '#0fb2f2', '#0f7ad2', '#ef5791', '#f7d460', '#b2e73f', '#f6b54d', '#5f6879', '#c3ccd5']
  formatSerie (serie, i) {
    serie.stack = serie.stack ? 'stack' : null
    serie.label = merge({}, {
      show: serie?.label?.show_values || serie.label?.show_percent,
      formatter: params => {
        const value = params.value.toLocaleString()
        const percent = `${sumBy(serie.data, 'value') ? (params.value / sumBy(serie.data, 'value') * 100).toFixed(0) : '-'}%`
        if (serie?.label?.show_values && serie.label?.show_percent) return `${value} (${percent})`
        if (serie?.label?.show_values) return value
        if (serie.label?.show_percent) return percent
        return '-'
      }
    }, this.forepaas?.options?.series?.label)
    serie.name = serie.name || this.generateName(serie)
    serie.data = serie.data || this.generateData(serie)
    serie.type = serie.type || this.forepaas?.options?.series?.type || 'line'

    if (serie.type === 'area') serie.areaStyle = serie.areaStyle || {}
    if (serie.type === 'scatter') {
      serie.symbolSize = serie.symbolSize || 20
    }
    if (serie.type === 'pie') {
      if (this.forepaas?.options?.donut) {
        serie.radius = ['30%', '70%']
        serie.itemStyle = {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        }
      }

      delete this.xAxis
      delete this.yAxis
    }
    return serie
  }

  generateStack (serie) {
    const cm = serie.forepaas.computeMode
    const data = serie.forepaas.data
    let stack = cm
    if (stack) stack += ' '
    stack += data
    if (serie.forepaas.evolScale) {
      const evol = 'evol-' + serie.forepaas.evolScale + '-' + serie.forepaas.evol
      if (stack) stack += ' '
      stack += evol
    }
    return stack
  }

  generateName (serie) {
    let scaleName = []
    for (const scale in serie.forepaas.scales) {
      scaleName.push([scale, serie.forepaas.scales[scale]])
    }
    scaleName = scaleName.map((scale) => {
      return scale.join('-')
    }).join(' ')
    if (scaleName) { return this.generateStack(serie) + ' ' + scaleName }
    return this.generateStack(serie)
  }

  generateData (serie) {
    let data = []
    if (this.forepaas.options.series.type === 'scatter') {
      for (let i = 0; i < this.forepaas.results.length; i++) {
        data.push(new EchartsResult(this.forepaas.results[i], serie, this.forepaas.axis.x, this.xAxis?.type, this.forepaas.options))
      }
    } else {
      for (const i in this.forepaas.sorted[serie.forepaas.yKey]) {
        const indexKey = this.forepaas.xLabeldOrder.findIndex(label => label === i)
        if (indexKey !== -1) {
          data[indexKey] = new EchartsResult(this.forepaas.sorted[serie.forepaas.yKey][i], serie, this.forepaas.axis.x, this.xAxis?.type, this.forepaas.options)
        }
      }
    }

    const nbDataPieToDisplay = 20
    if (this.forepaas.options.series.type === 'pie' && nbDataPieToDisplay < data.length) {
      // Sort data from biggest value to smallest
      data.sort((a, b) => b.value - a.value)
      let sum = 0
      const result = []

      // Only keep the "nbDataPieToDisplay" and get sum of the others
      for (let i = 0; i < data.length; i++) {
        if (i < nbDataPieToDisplay) {
          result.push(data[i])
        } else {
          sum += data[i].value
        }
      }

      // Create a new Pie piece with the sum and called as other
      result.push({ name: Vue.$t('echart_formatter.other'), value: sum })
      data = result
    }

    if (this.xAxis?.type === 'time') {
      data = _orderBy(data, 'name')
    }
    return data
  }

  getEvol () {
    return this.forepaas.query_params.evol && this.forepaas.query_params.evol.scale
  }

  generateResult () {
    this.forepaas.results = this.forepaas.results.map((result) => {
      result.axis = {
        x: {},
        y: {}
      }
      this.forepaas.axis.x.forEach((scale) => {
        result.axis.x[scale] = result.scales[scale]
      })
      this.forepaas.axis.y.forEach((scale) => {
        result.axis.y[scale] = result.scales[scale]
      })
      result.key = {
        x: JSON.stringify(result.axis.x),
        y: JSON.stringify(result.axis.y)
      }
      return result
    })
  }

  sortResult () {
    this.forepaas.sorted = {}
    this.forepaas.xLabels = []
    this.forepaas.results.forEach((result) => {
      this.forepaas.sorted[result.key.y] = this.forepaas.sorted[result.key.y] || {}
      if (this.forepaas.xLabels.indexOf(result.key.x) === -1) {
        this.forepaas.xLabels.push(result.key.x)
      }
      result.x = this.forepaas.xLabels.indexOf(result.key.x)
      this.forepaas.sorted[result.key.y][result.key.x] = result
    })
    this.forepaas.xLabeldOrder = cloneDeep(this.forepaas.xLabels)
    this.forepaas.xLabels = this.forepaas.xLabels.map((xLabel) => {
      return JSON.parse(xLabel)
    })

    this.generateXAxis()
    this.generateYAxis()
  }

  generateLabel (values) {
    let key, label, value
    label = ''
    for (key in values) {
      value = values[key]
      if (label) label += ' '
      label += value
    }
    return label
  }

  generateXAxis () {
    this.xAxis = merge({}, {
      type: 'category',
      axisLine: {
        show: false
      },
      axisLabel: {
        color: ['#97A7B7'],
        fontSize: 11,
        padding: [5, 0, 0, 0]
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      }
    }, this.xAxis)

    this.xAxis.type = this.forepaas?.options?.xAxis?.type || 'category'
    if (this.xAxis.type !== 'time') {
      this.xAxis.data = this.xAxis.data || this.forepaas.xLabels.map(this.generateLabel.bind(this))
    } else {
      this.xAxis.data = this.forepaas.xLabels.map(label => moment(label.date))
    }
  }

  generateYAxis () {
    this.yAxis = merge({}, {
      splitNumber: 2,
      type: 'value',
      axisLine: {
        show: false
      },
      axisLabel: {
        inside: true,
        fontFamily: 'Source Sans Pro',
        fontSize: 11,
        padding: [0, 0, 4, 0],
        verticalAlign: 'bottom',
        color: ['#97A7B7']
      },
      splitLine: {
        lineStyle: {
          shadowColor: '#F3F5F6',
          shadowOffsetX: -30,
          color: ['#F3F5F6']
        }
      },
      offset: 40,
      axisTick: {
        show: false
      }
    }, this.forepaas.options.yAxis)

    if (this.forepaas?.options?.yAxis?.type === 'category') {
      this.yAxis.data = this.xAxis.data
    }
  }

  removeLegendCompletly () {
    if (this.legend && has(this, 'forepaas.options.map') && !has(this, 'forepaas.options.legend')) {
      delete this.legend
    }
  }

  generateTooltip () {
    this.tooltip = merge({}, {
      trigger: 'axis',
      show: true,
      axisPointer: {
        type: 'none'
      },
      backgroundColor: 'white',
      textStyle: {
        color: '#3E4550',
        fontFamily: 'Source Sans Pro',
        fontSize: '11px',
        textShadowOffsetX: 10
      },
      padding: [15, 20, 0, 20],
      extraCssText: 'box-shadow: 0 10px 30px 0 rgba(151,167,183,0.3); border-radius: 10px;',
      triggerOn: 'mousemove|click',
      enterable: true,
      formatter: (params) => {
        let label = Array.isArray(params) ? params[0].axisValue : params.name
        if ((Array.isArray(params) && params[0].axisType === 'xAxis.time') || params.axisType === 'xAxis.time') label = moment(label).format('DD-MM-YYYY')

        const colorSpan = color => `
          <span style="display:inline-block; margin-right:10px; border-radius:100%; width:7px; height:7px; background-color: ${color}"></span>
        `
        let html = `
          <div style="margin-bottom: 10px; font-size: 13px;">
            <strong>${label}</strong>
          </div>
        `

        // If params is array (trigger: axis)
        if (Array.isArray(params)) {
          params.forEach((item, i) => {
            let value = item.data

            // Get value by type of data
            if (Array.isArray(value)) value = value.length ? value[1] : value
            else if (value && typeof item.data === 'object') value = item.data.value

            // Format value in tooltip
            if (value && typeof value === 'number') value = value.toLocaleString()
            if (!value && value !== 0) value = '-'
            const line = `
              <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; margin-top; 5px; font-size: 11px;">
                <span style="margin-right: 10px;">${colorSpan(item.color)} ${item.seriesName}</span>
                <span style="font-size: 13px;"><strong>${value}</strong></span>
              </div>
            `
            html += line
          })
        } else {
          // If params is object (trigger: item)
          let value = params.data

          // Get value by type of data
          if (Array.isArray(value)) value = value.length ? value[1] : value
          else if (value && typeof params.data === 'object') value = params.data.value

          // Format value in tooltip
          if (value && typeof value === 'number') value = value.toLocaleString()
          if (!value && value !== 0) value = '-'
          const line = `
            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; margin-top; 5px; font-size: 11px;">
              <span style="margin-right: 10px;">${colorSpan(params.color)} ${params.seriesName}</span>
              <span style="font-size: 13px;"><strong>${value}</strong></span>
            </div>
          `
          html += line
        }

        return `
          <div id="tooltip-echarts-${this.tooltipId}" style="margin-bottom: 11px">
            ${html}
          </div>
        `
      }
    }, this.forepaas.options.tooltip)
  }
}

export default EchartFormatter
