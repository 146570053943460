/* istanbul ignore file */
import Vue from 'vue'
import APPSERVICE from './APPSERVICE'
import DM from './DM'
import CC from './CC'
import DPE from './DPE'
import DS from './DS'
import DWH from './DWH'
import FPAPI from './FPAPI'
import IAM from './IAM'
import KING from './KING'
import ORGANIZATION from './ORGANIZATION'
import BILLING from './BILLING'
import SUPPORT from './SUPPORT'
import ML from './ML'
import QB from './QB'
import QUERY_ADMIN from './QUERY_ADMIN'
import STORAGE from './STORAGE'
import STORE from './STORE'
import NOTEBOOK_MANAGER from './NOTEBOOK-MANAGER'

export default {
  install (app) {
    const API = {
      APPSERVICE: new APPSERVICE('APPSERVICE'),
      DM: new DM('DM'),
      CC: new CC('CC'),
      DPE: new DPE('DPE'),
      DS: new DS('DS'),
      DWH: new DWH('DWH'),
      FPAPI: new FPAPI('FPAPI'),
      IAM: new IAM('IAM'),
      KING: new KING('KING'),
      ORGANIZATION: new ORGANIZATION('ORGANIZATION'),
      BILLING: new BILLING('BILLING'),
      SUPPORT: new SUPPORT('SUPPORT_API'),
      ML: new ML('ML'),
      QB: new QB('QB'),
      QUERY_ADMIN: new QUERY_ADMIN('QUERY_ADMIN'),
      STORAGE: new STORAGE('STORAGE'),
      STORE: new STORE('STORE'),
      NOTEBOOK_MANAGER: new NOTEBOOK_MANAGER('NOTEBOOK_MANAGER')
    }

    Vue.api = app.config.globalProperties.$api = API
  }
}
