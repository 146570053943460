// TODO: Check if it is used or not
const fpClickOutside = {
  beforeMount (el, { value, instance }, vNode) {
    const handler = (e) => {
      if (!instance) return
      const elements = e.path || (e.composedPath && e.composedPath())
      elements && elements.length > 0 && elements.unshift(e.target)
      if (el.contains(e.target)) return
      el.fpClickOutside.callback(e)
    }
    el.fpClickOutside = {
      handler: handler,
      callback: value
    }
    document.addEventListener('click', handler)
  },
  unmounted (el) {
    document.removeEventListener('click', el.fpClickOutside.handler)
    delete el.fpClickOutside
  },
  updated (el, { value }) { el.fpClickOutside.callback = value }
}

export default fpClickOutside
