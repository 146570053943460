const accents = (value) => {
  let toChange = value
  const nonAsciis = {
    a: '[àáâãäå]',
    ae: 'æ',
    c: 'ç',
    e: '[èéêë]',
    i: '[ìíîï]',
    n: 'ñ',
    o: '[òóôõö]',
    oe: 'œ',
    u: '[ùúûűü]',
    y: '[ýÿ]'
  }
  for (const key in nonAsciis) {
    toChange = toChange.replace(new RegExp(nonAsciis[key], 'g'), key)
  }
  return toChange
}

const SubdomainName = (value) => {
  try {
    let subdomain = value
    if (!value) return subdomain
    if (typeof subdomain !== 'string') return subdomain
    subdomain = subdomain.toLowerCase()
    subdomain = accents(subdomain)
    subdomain = subdomain.replace(/[^a-zA-Z0-9_]/g, '-')
    subdomain = subdomain.split('-').filter(s => s).join('-')

    return subdomain
  } catch (err) {
    /* istanbul ignore next */
    return ''
  }
}

export default SubdomainName
