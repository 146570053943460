import Vue from 'vue'
import Config from '@/shared/Config'
import _get from 'lodash/get'
import moment from 'moment'
import qs from 'qs'

const TIME_BETWEEN_CHECK_SESSION = (900 + Math.random() * 900) * 1000 // Every 15min-30-min

const TIME_BEFORE_REFRESH_SESSION = 3600 * 1000 // 1 hour

export default {
  state: {
    globalError: null,
    session: null,
    loading: true,
    resizing: false,
    internalLoading: false,
    preferences: null,
    store: {},
    organization_id: null,
    king_session: null,
    loadingStore: {},
    openSidebar: false,
    clickFromIframe: 0
  },
  mutations: {
    SET_CMP_SESSION (state, user) { state.king_session = user }, // Not WS
    SET_ORGANIZATION_ID (state, orgaId) { state.organization_id = orgaId }, // Not WS
    SET_GLOBAL_ERROR (state, error) { state.globalError = error }, // Not WS
    SET_SESSION (state, session) { state.session = session }, // Not WS
    SET_RESIZING (state, resizing) { state.resizing = resizing }, // Not WS
    SET_PREFERENCES (state, preferences) { state.preferences = preferences }, // Not WS
    SET_CONFIGURATION (state, configuration) { state.configuration = configuration }, // Not WS
    SET_LOADING (state, loading) { state.loading = loading }, // Not WS
    SET_INTERNAL_LOADING (state, loading) { state.internalLoading = loading }, // Not WS
    SET_STORE (state, p) { // Not WS
      state.store[p.consumer] = p.value
    },
    SET_LOADING_STORE (state, p) { // Not WS
      state.loadingStore[p.consumer] = p.value
    },
    SET_OPEN_SIDE_BAR (state, value) { // Not WS
      state.openSidebar = value
    },
    CLICK_FROM_IFRAME (state) { // Not WS
      state.clickFromIframe += 1
    }
  },
  actions: {
    async APP_INIT ({ commit, dispatch }) {
      const preferences = await Vue.api.IAM.preferences(_get(Vue, '$router.currentRoute.value.query.client_id'))
      commit('SET_PREFERENCES', preferences)
      await dispatch('SESSION_INIT')
      commit('SET_LOADING', false)
    },
    async STORE_INIT ({ state, commit }, consumers = []) {
      const consumersArray = Array.isArray(consumers) ? consumers : [consumers]
      const consumersToLoad = []

      consumersArray.forEach(c => {
        // Store is large, thus we set a loading flag to prevent
        // multiple components to call STORE_INIT at the same time
        if (!state.store?.[c] && !state.loadingStore?.[c]) {
          const request = Vue.api.STORE.get(c)
          commit('SET_LOADING_STORE', {
            consumer: c,
            value: request
          })
          consumersToLoad.push({
            consumer: c,
            value: request
          })
        } else if (state.loadingStore?.[c]) {
          consumersToLoad.push({
            consumer: c,
            loading: true,
            value: state.loadingStore[c]
          })
        }
      })

      const config = await Config()
      await Promise.all(consumersToLoad.map(async c => {
        const res = await c.value

        // On Data platform we display template AAB and FP we display template ML
        if (c.consumer === 'project') {
          if (config.DATA_PLATFORM_ACCESS) {
            delete res.templates['machine-learning']
          }
        }

        if (!c.loading) {
          commit('SET_STORE', { consumer: c.consumer, value: res })
          commit('SET_LOADING_STORE', { consumer: c.consumer, value: null })
        }
      }))
    },
    async REFRESH_SESSION ({ commit, dispatch }) {
      const session = await Vue.api.IAM.checksession()
      if (session?.token_duration) {
        let refreshTime = session.token_duration * 0.9
        if (refreshTime > 2 * 3600 * 1000) {
          refreshTime = 2 * 3600 * 1000 // 2h max
        }
        setTimeout(_ => {
          dispatch('REFRESH_SESSION')
          // We plan a refresh in 90% of the duration of the session
        }, refreshTime)
      }
      if (session) commit('SET_SESSION', session)
      else {
        await dispatch('WEBSOCKET_CLOSE')
        commit('SET_SESSION', null)
      }
    },
    async SESSION_INIT ({ commit, state, dispatch, getters }) {
      await dispatch('REFRESH_SESSION')
      if (getters.NEED_MFA) return
      if (state.session) {
        const config = await Config()
        const redirectUri = _get(Vue, '$router.app._route.query.redirect_uri')
        const responseType = _get(Vue, '$router.app._route.query.response_type')
        if (redirectUri && !responseType) {
          const url = `${config.IAM}/oauth/confirm?${qs.stringify(Vue.$router.app._route.query)}`
          document.location.href = url
          return
        }
        commit('SET_ORGANIZATION_ID', config.ORGANIZATION_ID)
        if (getters.AUTHENTICATION_WITH_KING) await dispatch('LOGIN_CMP')
        else if (getters.SESSION) await dispatch('LOGIN_DONE')
        await dispatch('WEBSOCKET_INIT')
        if (Vue.$analytics && state.session?.email) {
          Vue.$analytics.identify(state.session.email, {}, getters.ANALYTICS_CONFIGURATION)
        }
      }
    },
    async REFRESH_CMP ({ dispatch }) {
      dispatch('LOGIN_CMP')
    },

    async LOGIN_CMP ({ dispatch, commit }) {
      let user = null
      try {
        const [userGet, organizations] = await Promise.all([Vue.api.KING.users.me({ noReconnect: true }, true), Vue.api.ORGANIZATION.organizations.me()])
        user = userGet
        user.organizations = organizations
        commit('SET_CMP_SESSION', user)
      } catch (_err) {
        // eslint-disable-next-line no-console
        console.info('UserNotLoggedWithKingAccount')
      }
      // if session to old
      if (user && user.token && moment() - moment(user.token.updated_at) > TIME_BEFORE_REFRESH_SESSION) {
        return dispatch('REFRESH_CMP')
      }

      setTimeout(_ => {
        dispatch('LOGIN_CMP')
      }, TIME_BETWEEN_CHECK_SESSION)
      await dispatch('LOGIN_DONE')
    },
    async LOGIN ({ dispatch }, { login, email, password }) {
      await Vue.api.IAM.login({ login, email, password })
      await dispatch('SESSION_INIT')
    },
    async SIGNUP (_, { email, password, name, tc_accepted, redirect_uri, tokenJoin, organizationId }) {
      await Vue.api.IAM.signup({ email, password, name, tc_accepted, redirect_uri, tokenJoin, organizationId })
    },
    async PASSWORD_RESET (_, { email }) {
      return Vue.api.IAM.passwordReset({ email })
    },
    async PASSWORD_RENEW ({ commit }, { token, newPassword }) {
      await Vue.api.IAM.passwordRenew(token, newPassword)
    },
    async LOGOUT ({ dispatch, commit }, redirection = true) {
      try {
        await Vue.api.IAM.logout()
      } catch (err) {
        // Error of logout should not block the leave of the app from UI
        // eslint-disable-next-line no-console
        console.error(err)
      }
      await dispatch('WEBSOCKET_CLOSE')
      if (redirection) {
        await Vue.$router.push({
          path: Vue.$router.currentRoute.value.path,
          query: {
            ...Vue.$router.currentRoute.value.query,
            logout: null
          }
        })
      }
      commit('SET_SESSION', null)
    },
    async SEND_MFA_CODE (_, { type }) {
      return Vue.api.IAM.sendMFACode(type)
    },
    // Used in the login page
    async VALIDATE_MFA_CODE ({ commit, dispatch }, { type, otp }) {
      const session = await Vue.api.IAM.validateMFACode({ type, otp })
      if (session) commit('SET_SESSION', session)
      await dispatch('SESSION_INIT')
    },
    // Used in the user page
    async VALIDATE_KING_MFA_CODE ({ commit, dispatch }, { type, otp }) {
      const session = await Vue.api.KING.validateMFACode({ type, otp })
      if (session) commit('SET_CMP_SESSION', session)
      await dispatch('CHECK_CMP_SESSION')
    },
    async RESET_MFA_CODE ({ commit, dispatch }, { code }) {
      const session = await Vue.api.KING.resetMFA(code)
      if (session) commit('SET_CMP_SESSION', session)
    }
  },
  getters: {
    ANALYTICS_CONFIGURATION (_, getters) {
      let userHashIntercom = getters.SESSION?.intercom ? getters.SESSION.intercom.secret : null
      if (!userHashIntercom) userHashIntercom = getters.KING_SESSION?.intercom ? getters.KING_SESSION.intercom.secret : null
      return {
        integrations: {
          Intercom: userHashIntercom ? { user_hash: userHashIntercom } : false
        }
      }
    },
    NEED_MFA (state) {
      return state.session?.acr === 'aal1'
    },
    GLOBAL_ERROR (state) { return state.globalError },
    SESSION (state) { return state.session },
    AUTHENTICATION_WITH_KING (state) {
      return ['forepaas'].includes(state.session?.authentication_type)
    },
    KING_SESSION (state) { return state.king_session },
    KING_ORGANIZATION_ACCESS (state) {
      if (!state.organization_id) return null
      if (!state.king_session) return null
      const userFromOrga = state.king_session.organizations.find(o => o.id === state.organization_id)?.users?.[0]
      return userFromOrga ? userFromOrga.role === 'admin' ? '*' : userFromOrga.access : []
    },
    VALID_SESSION (state) {
      const session = state.session
      if (!session) return false
      // Removed since we don't have the password_renew feature anymore
      // if (session.password_renew) return false
      if (session.acr && session.acr === 'aal1') return false
      return true
    },
    RESIZING (state) { return state.resizing },
    PREFERENCES (state) { return state.preferences },
    LOADING (state) { return state.loading },
    INTERNAL_LOADING (state) { return state.internalLoading },
    STORE_LOADING (state) { return state.loadingStore },
    STORE_PACKAGES (state) { return state.store },
    OPEN_SIDE_BAR (state) { return state.openSidebar },
    CLICK_FROM_IFRAME (state) {
      return state.clickFromIframe
    }
  }
}
