import Vue, { createApp } from 'vue'

import { contextMenu, draggable, droppable, dropzone, editContent, editContextContent, fpClickOutside, fpClipboard, resizable, sortable } from './directives/index'

import FpuiFormComponents from './components/form/index'
import FpLoaderComponents from './components/loader/index'
import FpuiModalComponents from './components/modals/index'
import FpTree from './components/tree-view/index'
import FpuiTabComponents from './components/tabs/index'
import FpuiStepsComponents from './components/steps/index'
import FpuiTableComponents from './components/table/index'
import FpuiDatePickerComponents from './components/datepicker/index'
import VuePhoneNumberInput from 'vue-phone-number-input'
import FpuiDashboardingLayoutComponents from './components/dashboarding/index'
import { Authentication } from './components/authentication/index'
import AutoFormComponents from './components/auto-form/components/index'
import FpAppComponents from './components/app/index'
import Echarts from 'vue-echarts'
import FpuiEChartsComponents from './components/echarts/index'
import Apis from './plugins/Api/index'
import WebSockets from './plugins/WebSockets'
import fpMessageBlock from './plugins/FpMessageBlock/index'
import VueVirtualScroller from './plugins/VirtualScroll'
import filters from './filters/index'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import VModal from './plugins/vue-js-modal/src/index'

// import VueResizeObserver from 'vue-resize-observer'
import Vue3Sanitize from 'vue-3-sanitize'
// import vuexI18n from 'vuex-i18n'
import resize from 'vue-element-resize-detector'
import VTooltip from 'v-tooltip'
import VueClipboard from 'vue-clipboard2'
import ToggleButton from 'vue-js-toggle-button'
import AsyncComputed from 'vue-async-computed'
import Plugins from './plugins/index'
import Acl from './plugins/Acl'

import FpuiEcharts from './components/echarts/FpuiECharts.vue'
import _merge from 'lodash/merge'

// TODO: maybe no need to import for dashboard. check 'src/dashboard/store/index.js'
const createFpuiApp = (options) => {
  const app = createApp(options)

  app.use(Apis)
  app.use(WebSockets)
  app.use(fpMessageBlock)
  app.use(VueVirtualScroller)

  // app.use(VueResizeObserver)
  app.use(resize)
  app.use(VTooltip, {
    defaultDelay: {
      show: 750
    },
    defaultPopperOptions: {
      modifiers: {
        preventOverflow: {
          escapeWithReference: true
        }
      }
    }
  })
  app.use(VueClipboard)
  app.use(ToggleButton)
  app.use(AsyncComputed)
  app.use(Plugins)
  app.use(Acl)

  app.use(Vue3Sanitize, {
    allowedTags: ['img', 'span', 'div', 'i', 'br', 'b', 'strong', 'i', 'a'],
    allowedAttributes: {
      '*': ['class', 'style'],
      img: ['src', 'class'],
      a: ['href', 'target']
    },
    allowedSchemes: ['data', 'http', 'https']
  })
  app.use(VModal, {
    dynamic: true,
    dynamicDefaults: {
      adaptive: true,
      scrollable: true
    }
  })
  Vue.$sanitize = app.config.globalProperties.$sanitize
  app.mixin({
    methods: {
      CSSObject (a, cls, opts) {
        const css = { a } || null
        opts = opts || {}
        const itemCss = css ? css[cls] : {}
        if (opts.hover) {
          return itemCss ? _merge({}, itemCss.default, itemCss.hover) : {}
        }
        return itemCss ? itemCss.default : {}
      }
    }
  })

  app.directive('contextMenu', contextMenu)
  app.directive('draggable', draggable)
  app.directive('droppable', droppable)
  app.directive('dropzone', dropzone)
  app.directive('editContent', editContent)
  app.directive('editContextContent', editContextContent)
  app.directive('fpClickOutside', fpClickOutside)
  app.directive('fpClipboard', fpClipboard)
  app.directive('resizable', resizable)
  app.directive('sortable', sortable)



  app.component('VuePhoneNumberInput', VuePhoneNumberInput)

  app.component('FpuiTreeView', FpTree)

  app.component('FpuiTabs', FpuiTabComponents.FpTabs)
  app.component('FpuiTab', FpuiTabComponents.FpTab)
  app.component('FpuiTabIf', FpuiTabComponents.FpTabIf)

  app.component('Authentication', Authentication)

  app.component('Echarts', Echarts)
  app.component('FpuiEcharts', FpuiEcharts)

  const componentList = {
    ...FpuiFormComponents,
    ...FpuiTabComponents,
    ...FpuiTableComponents,
    ...FpuiStepsComponents,
    ...FpuiModalComponents,
    ...FpLoaderComponents,
    ...FpuiEChartsComponents,
    ...FpuiDatePickerComponents,
    ...FpuiDashboardingLayoutComponents,
    ...FpAppComponents,
    ...AutoFormComponents
  }

  for (const componentName in componentList) {
    app.component(componentName, componentList[componentName])
  }

  app.config.globalProperties.$filters = { ...filters }

  return app
}
export default createFpuiApp
