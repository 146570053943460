import Config from '@/shared/Config'
import Store from '@/shared/store'
import _get from 'lodash/get'
import Vue from 'vue'

/**
 * WebSockets class to manage WebSocket connections and event handling.
 *
 * This class provides methods to:
 * - Initialize WebSocket connections.
 * - Handle incoming messages and close events.
 * - Subscribe and unsubscribe to specific events.
 * - Send messages to the WebSocket server.
 * - Manage legacy ping functionality.
 */
class WebSockets {
  constructor () {
    /**
    * @private
    * @type {Object}
    * @description Stores the WebSocket client instances.
    */
    this.sockets = {}
    /**
    * @private
    * @type {Object}
    * @description Stores event listeners for the WebSocket clients.
    */
    this.listeners = {}
    /**
    * @deprecated This constant is considered legacy and may be removed in future versions.
    * Interval in milliseconds for sending ping messages to the WebSocket server.
    * This is used to keep the connection alive.
    */
    this.WEBSOCKET_PING_PONG_INTERVAL = 5000
  }

  /**
  * Initializes the WebSocket connection.
  * @param {string} name - The name of the WebSocket client.
  * @param {Function} [onConnect=null] - Optional callback function to execute when the connection is established.
  * @returns {Promise<void>}
  */
  /* istanbul ignore next */
  async init (name, onConnect = null) {
    // eslint-disable-next-line no-console
    console.info(`InitSocket::${name}`)
    const config = await Config()
    let websocketUrl = config[`${name}_WS`]
    // Add organization_id to the websocket qs if in the special "ORGANIZATION_MODE"
    // which is basically organization_id = project_id, in the IAM only
    if (name === 'IAM' && config.ORGANIZATION_MODE && config.ORGANIZATION_ID) {
      websocketUrl = `${websocketUrl}?organization_id=${config.ORGANIZATION_ID}`
    }
    const client = new WebSocket(websocketUrl)
    this.sockets[name] = new Promise(resolve => {
      client.onmessage = (event) => this.onClientMessage(event, client, name, onConnect, resolve)
      client.onclose = (event) => this.onClientClose(event, client, name, onConnect)
    })
  }

  /**
  * Handles incoming messages from the WebSocket client.
  * @param {MessageEvent} event - The message event containing the data from the server.
  * @param {WebSocket} client - The WebSocket client instance.
  */
  async onClientMessage (event, client, name, onConnect, resolve) {
    let message
    try {
      message = JSON.parse(event.data)
    } catch (_) {
      message = event.data
    }
    // When a client is connected, we store the socket_id in the store
    if (message.type === 'event' && message.event === 'connected') {
      try {
        const SocketId = _get(message, 'data.socket_id')
        if (SocketId) Store.commit('SET_SOCKET_ID', SocketId)
      } catch (err) {
        console.error(err.stack)
      }
      // Legacy websocket ping support
      if (name !== 'EVENT_MANAGER') this.ping(name)
      if (onConnect) onConnect(client)
      return resolve(client)
    }

    // Handling legacy ping/pong messages
    if (message.type === 'result' && message.data && message.data.type === 'PONG' && name !== 'EVENT_MANAGER') {
      client.pingTimeout = setTimeout(_ => this.ping(name), this.WEBSOCKET_PING_PONG_INTERVAL)
    }

    // Handling message for Vuex store usage
    if (message.event || (message.type.includes('forepaas') && Object.keys(message).includes('fpsocketid'))) {
      const event = message.event || 'event_manager-event'
      if (this.listeners[event]) {
        this.listeners[event].forEach(l => {
          const params = event === 'event_manager-event' ? message : message.data
          return l(params)
        })
      }
    }
  }

  /**
 * Handles the WebSocket client's close event.
 * @param {Event} event - The close event.
 * @param {WebSocket} client - The WebSocket client.
 * @param {string} name - The name of the WebSocket client.
 * @param {Function} onConnect - The callback function to execute on reconnect.
 */
  async onClientClose (event, client, name, onConnect) {
    // Handling legacy ping/pong clear timeout
    if (client.pingTimeout && name !== 'EVENT_MANAGER') clearTimeout(client.pingTimeout)
    // We add a timer to reconnect only after a delay (to prevent loop of reconnect)
    setTimeout(_ => {
      this.init(name, onConnect)
    }, 5000)
  }

  /**
  * Closes the WebSocket connection.
  */
  async close () {
    for (const name in this.sockets) {
      // eslint-disable-next-line no-console
      console.info(`CloseSocket::${name}`)
      const client = await this.sockets[name]
      client.onclose = () => { /* ignore this stop event */ }
      client.close()
      delete this.sockets[name]
    }
  }

  /**
  * Subscribes to a specific event with a callback function.
  * @param {string} event - The name of the event to subscribe to.
  * @param {Function} callback - The callback function to execute when the event is triggered.
  */
  async subscribe (event, callback) {
    this.listeners[event] = this.listeners[event] || []
    this.listeners[event].push(callback)
  }

  /**
  * Unsubscribes from a specific event.
  * @param {string} event - The name of the event to unsubscribe from.
  * @param {Function} callback - The callback function to remove from the event's listeners.
  */
  async unsubscribe (event, callback) {
    this.listeners[event] = this.listeners[event] || []
    const idx = this.listeners[event].indexOf(callback)
    if (idx !== -1) this.listeners[event].splice(idx, 1)
    if (!this.listeners[event].length) delete this.listeners[event]
  }

  /**
  * Sends a message to the WebSocket server.
  * @param {string} name - The name of the WebSocket client.
  * @param {Object} message - The message object to send.
  */
  async send (name, message) {
    if (!this.sockets[name]) return
    const client = await this.sockets[name]
    client.send(JSON.stringify(message))
  }

  /**
  * Sends a ping message to the WebSocket server.
  * @param {string} clientName - The name of the WebSocket client.
  * @deprecated This method legacy, please ping using the server instead
   */
  async ping (clientName) {
    this.send(clientName, {
      method: 'ping'
    })
  }
}
export default {
  install () {
    Vue.WebSockets = new WebSockets()
  }
}
