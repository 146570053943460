import bytes from './Bytes'
import capitalize from './Capitalize'
import cron from './Cron'
import date from './Date'
import duration from './Duration'
import dashboardurationLetter from './DurationLetter'
import number from './Number'
import octets from './Octets'
import price from './Price'
import subdomainName from './SubdomainName'

export default { bytes, capitalize, cron, date, duration, dashboardurationLetter, number, octets, price, subdomainName }

