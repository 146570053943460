import Config from '@/shared/Config'
import socket from '@/shared/store/socket'
import fpapi from '@/shared/store/fpapi'
import king from '@/shared/store/king'
import session from '@/shared/store/session'
import storage from './storage'
import { createStore } from 'vuex'


const Store = createStore({
  modules: { socket, king, fpapi, storage, session }
})

Config().catch(err => {
  Store.commit('SET_GLOBAL_ERROR', err)
})

export default Store
