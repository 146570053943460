import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'

class User extends APIObject {
  constructor (options) {
    super('KING', options, true)
    this.defaultOptions()
  }

  defaultOptions (options) {
    this._id = this.id = this.uid || ''
    this.email = this.email || ''
    this.civility = this.civility || 0
    this.preferences = this.preferences || {}
    this.consent_details = this.consent_details || {}
    this.onboarding = this.onboarding || {}
    this.preferences.home_page_preferences = this.preferences.home_page_preferences || {}
    this.tc_accepted_at = this.tc_accepted_at || null // if the user accepted terms and condition or not
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'onboarding.trials_left'
    ])
    return obj
  }

  async save () {
    return super.save({
      method: 'PUT',
      retries: 0,
      url: 'v4/profile'
    })
  }

  async resetPassword ({ password, oldpassword }) {
    return await this.request({
      url: 'v4/passwordChange',
      method: 'POST',
      retries: 0,
      data: {
        password,
        oldpassword
      }
    })
  }

  async listSession () {
    return await this.request({
      method: 'GET',
      url: 'v4/sessions',
      retries: 0
    })
  }

  async revokeSession (id) {
    return await this.request({
      method: 'DELETE',
      url: `v4/sessions/${id}`,
      retries: 0
    })
  }
}
class Users extends API {
  constructor (ID) {
    super(ID, true)
  }

  async me (options = {}, userClass = false) {
    options.noReconnect = options.noReconnect || false
    options.noOrganizationId = options.noOrganizationId || false

    const user = await this.request({
      method: 'GET',
      url: 'v4/checksession',
      retries: 0,
      ...options
    })

    if (userClass) return new User(user)
    return user
  }

  async list (options = {}, userClass = false) {
    return await this.request({
      method: 'GET',
      url: 'v4/users',
      retries: 0,
      ...options
    })
  }
}

export default Users
export {
  User,
  Users
}
