import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _uniq from 'lodash/uniq'
import moment from 'moment'
import Vue from 'vue'

class Dataplant extends APIObject {
  constructor (options) {
    // Init
    super('FPAPI', options, true)
    this.definition = null
    this.service_group_resources = this.service_group_resources || {}
    this.services = this.services || []
    this.display_settings = this.display_settings || {
      icon: {
        color: '#00CCF9',
        type: 'fp4-four-cubes-project'
      },
      services_wizard: {},
      home_layout: []
    }
    this.display_settings.services_wizard = this.display_settings.services_wizard || {}

    if (this.services.includes('dm')) {
      if (!this.services.includes('data-catalog')) this.services.push('data-catalog')
      if (!this.services.includes('lakehouse')) this.services.push('lakehouse')
    }
  }

  get isAborted () {
    return this.status === 'aborted'
  }

  get storage () {
    return parseInt(this.datatank)
  }

  get fpu () {
    let total = 0
    for (const service in this.service_group_resources) {
      total += this.service_group_resources[service].fpu * this.service_group_resources[service].instances
    }
    return total
  }

  get serviceNames () {
    const names = []
    for (const name in this.service_group_resources) {
      if (name.indexOf('-ml-api') !== -1) {
        names.push('ml-api')
      } else if (name.indexOf('-api') !== -1) {
        names.push('api')
      } else if (name.indexOf('-app') !== -1) {
        names.push('app')
      } else names.push(name)
    }
    return _uniq(names)
  }

  get cpu () {
    return this.fpu * 0.5
  }

  get memory () {
    return this.fpu * 2
  }

  get apps () {
    let total = 0
    for (const service in this.service_group_resources) {
      if (service.indexOf('-app') !== -1) { total++ }
    }
    return total
  }

  get apis () {
    let total = 0
    for (const service in this.service_group_resources) {
      if (service.indexOf('-api') !== -1 && service.indexOf('-ml-api') === -1) { total++ }
    }
    return total
  }

  get models () {
    let total = 0
    for (const service in this.service_group_resources) {
      if (service.indexOf('-ml-api') !== -1) { total++ }
    }
    return total
  }

  // Returns if the Dataplant is ready, 10 minutes
  get isReady () {
    if (this.error) return false
    if (new Date() - new Date(this.created_at) > 1800000) return true
    return this.global_status && this.global_status !== 'project_deploying'
  }

  // Returns if the Dataplant is limited in term of use
  get isLimited () {
    if (this.error === 'IAASRequestError::not found') return false
    return this.error
  }

  // Check if the dataplant has been created less than 15 minutes ago
  get hasBeenCreatedInLast15MIn () {
    return moment().unix() - moment(this.created_at).unix() < 900
  }

  // Migration from Dataplant to Project
  // The following getters are used for all the leftovers we may have
  // forgot during the migration (PAAS-11474)
  get name () {
    return this.display_name
  }

  get dataplant_id () {
    return this.id
  }

  get dataplant () {
    return this.plan
  }

  async refresh () {
    const data = await this.request({
      method: 'GET',
      url: `v1/projects/${this.id}`
    })
    data.service_group_resources = data.service_group_resources || {}
    this.assign(this._filter(data))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'account_id',
      'acl',
      'created_at',
      'creator_email',
      'dataplant_id',
      'error',
      'error_'
    ])
    return obj
  }

  getUsage (srv = null) {
    if (srv === 'summary') srv = null
    if (!srv) return this.fpu
    if (!this.service_group_resources) return 0
    if (srv === 'app') {
      let total = 0
      for (const service in this.service_group_resources) {
        const srv = this.service_group_resources[service]
        if (service.indexOf('-app') !== -1) { total += srv.instances * srv.fpu }
      }
      return total
    }
    if (srv === 'ml-api') {
      let total = 0
      for (const service in this.service_group_resources) {
        const srv = this.service_group_resources[service]
        if (service.indexOf('-ml-api') !== -1) { total += srv.instances * srv.fpu }
      }
      return total
    }

    if (srv === 'api') {
      let total = 0
      for (const service in this.service_group_resources) {
        const srv = this.service_group_resources[service]
        if (service.indexOf('-api') !== -1 && service.indexOf('-ml-api') === -1 && service.indexOf('-model-api') === -1) {
          total += srv.instances * srv.fpu
        }
      }
      return total
    }
    if (!this.service_group_resources[srv]) return 0
    return this.service_group_resources[srv].instances * this.service_group_resources[srv].fpu
  }

  getUsageDisplay (srv = null) {
    if (srv === 'summary') srv = null
    if (!srv) {
      let freeFpu = 0
      if (this.services) {
        // Check if QB or AppService deployed
        if (this.services.includes('am')) freeFpu++
        if (this.services.includes('appservice')) freeFpu++
      }

      return `${this.fpu} (${freeFpu} ${Vue.$t('dataplant.fpu.free')})`
    }

    const free = {
      datastore: 1,
      dpe: 1,
      querybuilder: 1,
      authentication: 1,
      infrastructure: 1,
      ml: 1
    }
    const usage = this.getUsage(srv)
    if (!usage) return '-'
    return `${usage}${free[srv] ? `(${free[srv]} ${Vue.$t('dataplant.fpu.free')})` : ''}`
  }


  getServiceDefinition (name) {
    if (!this.definition) return null
    if (name.indexOf('model-api') !== -1) return this.definition['model-api']
    if (name.indexOf('ml-api') !== -1) return this.definition['ml-api']
    if (name.indexOf('ml-score') !== -1) return this.definition['ml-score']
    if (name.indexOf('ml-train') !== -1) return this.definition['ml-train']
    if (name.indexOf('api') !== -1) return this.definition.api
    if (name.indexOf('app') !== -1) return this.definition.app
    return this.definition[name]
  }

  async restartPod (podName) {
    return await this.request({
      method: 'DELETE',
      url: `/dataplants/${this.dataplant_id}/pods/${podName}`
    })
  }

  async logs (podName, tail = 100) {
    return await this.request({
      method: 'GET',
      url: `/dataplants/${this.dataplant_id}/pods/${podName}/logs`,
      params: {
        sinceSeconds: 3600 * 24 * 7,
        tailLines: tail
      }
    })
  }

  async updateBudget (serviceName, { size, instances }) {
    const res = await this.request({
      method: 'PUT',
      url: `/dataplants/${this.dataplant_id}/budget/${serviceName}`,
      data: {
        fpu: size,
        instances
      }
    })
    this.service_group_resources[serviceName] = {
      fpu: size,
      instances
    }
    return res
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v1/projects/${this.dataplant_id}`
    })
  }

  async delete (token, force = false) {
    // TODO: Handle errors
    const r = await this.request({
      method: 'DELETE',
      url: `/v1/projects/${this.id}`,
      params: {
        tokenRemove: token,
        force: force
      }
    })
    return r
  }

  async loadDefinition () {
    if (this.definition) return
    this.definition = await this.request({
      method: 'GET',
      url: '/definition',
      params: {
        offer: this.offer
      }
    })
  }

  // Manage project services
  async addService (projectId, store_package_url) {
    return await this.request({
      method: 'PATCH',
      url: `/v1/projects/${projectId}/services`,
      data: {
        op: 'add',
        store_package_url
      }
    })
  }

  async removeService (projectId, store_package_url) {
    return await this.request({
      method: 'PATCH',
      url: `/v1/projects/${projectId}/services`,
      data: {
        op: 'remove',
        store_package_url
      }
    })
  }
}

class Dataplants extends API {
  constructor () {
    super('FPAPI', true)
  }

  async get (id) {
    return this.request({
      method: 'GET',
      url: `/v1/projects/${id}`
    })
  }

  async findOne (id) {
    const project = await this.request({
      method: 'GET',
      url: `/v1/projects/${id}`
    })
    return new Dataplant(_omit(project, ['error']))
  }

  async checkDomain (subdomain, domain, organizationId) {
    return await this.request({
      method: 'GET',
      url: `/check_domain/${subdomain}/${domain}`,
      params: {
        organization_id: organizationId
      }
    })
  }

  // TRINO
  async activateTrino (dataplantId, budget = { fpu: 4, instances: 1 }) {
    // analytics tracking
    Vue.$analytics.track('Query engine activated', {
      nb_of_instances: budget.instances,
      resources_allocated: budget.fpu * budget.instances,
      resources_type: 'DPU-S',
      engine: 'Trino'
    })

    return this.request({
      method: 'POST',
      url: `/dataplants/${dataplantId}/services/trino`,
      data: {
        budget
      }
    })
  }

  async updateTrino (dataplantId, budget) {
    return this.request({
      method: 'PUT',
      url: `/dataplants/${dataplantId}/services/trino`,
      data: {
        budget
      }
    })
  }

  async desactivateTrino (dataplantId) {
    // analytics tracking
    Vue.$analytics.track('Query Engine deactivated')

    return this.request({
      method: 'DELETE',
      url: `/dataplants/${dataplantId}/services/trino`,
      data: {}
    })
  }

  // Projects endpoints
  async create (p) {
    const project = await this.request({
      method: 'POST',
      url: '/v1/projects',
      data: {
        description: p.description,
        name: p.name,
        domain: p.domain,
        organization_id: p.organization_id,
        plan: p.plan,
        provider: p.provider,
        region: p.region,
        subdomain: p.subdomain,
        store_package_urls: p.store_package_urls
      }
    })
    return project
  }


  async list (organizationId, lite = false) {
    const params = {
      organization: organizationId || undefined
    }
    if (lite) params.detailed = false

    const data = await this.request({
      method: 'GET',
      url: '/v1/projects',
      params
    })
    return data.map(project => {
      return new Dataplant(_omit(project, ['error']))
    })
  }
}

export default Dataplants
export {
  Dataplant,
  Dataplants
}
