import { API, APIObject } from '@/shared/plugins/Api/API'
import _set from 'lodash/set'
import _omit from 'lodash/omit'
import Config from '@/shared/Config'

class Alert extends APIObject {
  constructor (options) {
    // Init
    super('CC', options)

    // Default variables
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.tags.path = this.tags?.path || ''
    this.tags.tags = this.tags?.tags || []
    this.uid = this.uid || null
    this.type = this.type || 'health'
    this.target = this.target || {
      type: null,
      dataplant_id: null,
      cluster: null,
      resource_id: '.*',
      only_cron: false
    }
    this.notify = this.notify || {
      type: 'once',
      wait_for: '0m',
      on_resolution: false
    }
    this.triggers = this.triggers || []
    this.subscribers = this.subscribers || {
      users: [],
      iam_groups: [],
      external_consumers: []
    }
  }


  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }


  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name',
      'status'
    ])
    return obj
  }


  async create () {
    const dataplantUrl = await this.dataplantUrl()
    if (!dataplantUrl) return
    return await super.create({
      method: 'POST',
      url: `${dataplantUrl}/control-center/v1/alerts`
    })
  }

  async save () {
    const dataplantUrl = await this.dataplantUrl()
    if (!dataplantUrl) return
    return super.save({
      method: 'PUT',
      url: `${dataplantUrl}/control-center/v1/alerts/${this._id}`
    })
  }

  async dataplantUrl () {
    const config = await Config()
    return config.BASE_URL
  }
}

class Alerts extends API {
  async list () {
    const alerts = await this.request({
      method: 'GET',
      url: '/v1/alerts'
    })

    return alerts
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Alert(item)
  }
}

export default Alerts
export {
  Alert,
  Alerts
}
