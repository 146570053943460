/* eslint-disable camelcase */
import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import Vue from 'vue'
import Config from '@/shared/Config'
import urljoin from 'url-join'

class User extends APIObject {
  constructor (options) {
    super('IAM', options)
    this.defaultOptions()
  }

  defaultOptions () {
    this._id = this._id || ''
    this.civility = this.civility || 0
    this.firstname = this.firstname || ''
    this.lastname = this.lastname || ''
    this.email = this.email || ''
    this.tags = this.tags || []
    this.active = typeof (this.active) !== 'undefined' ? this.active : true
    this.internal = typeof (this.internal) !== 'undefined' ? this.internal : true
    this.directory = this.directory || []
    this.configuration = this.configuration || {}
    this.configuration.logo = this.configuration.logo || ''
    this.configuration.color = this.configuration.color || ''
    this.login = this.login || ''
    this.password = this.password || null
    this.old_passwords = this.old_passwords || null
    this.phone = this.phone || ''
    this.password_created_at = this.password_created_at || null
    this.password_expire = this.password_expire || new Date()
    this.roles = this.roles || []
    this.groups = this.groups || []
    this.name_original = this.name // this.name is already in use so we use name_original instead
    this.name = this.display_name_full
    this.invitee = this.invitee || false
    this.tc_accepted_at = this.tc_accepted_at || null // if the user accepted terms and condition or not
  }

  get display_name () {
    if (this.firstname || this.lastname) return `${this.firstname} ${this.lastname}`.trim()
    return ''
  }

  set display_name (v) {
    // Do nothing, it's better than handling it in EditorMixin.js for the IAM
  }

  get display_name_full () {
    const civility = [0, 1, 2, '0', '1', '2'].includes(this.civility) ? Vue.$t(`user_civility.${this.civility}`) : this.civility
    return [civility, this.firstname, this.lastname].filter(e => e).join(' ')
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      'apikeys',
      'name'
    ])
    return obj
  }

  async create (forOrganization) {
    const config = await Config()
    const url = forOrganization ? urljoin(config.FPAPI, '/iam/v4/users') : 'v4/users'
    const headers = forOrganization ? { 'Organization-Id': config.ORGANIZATION_ID } : {}
    return super.create({
      method: 'POST',
      url,
      headers
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v4/users/${this._id}`
    })
  }

  generatePassword (id, send) {
    return this.request({
      method: 'get',
      url: `v4/users/${id}/generatePassword?send=${send}`
    })
  }

  generateAuthenticator (id) {
    return this.request({
      method: 'post',
      retries: 0,
      url: `v4/users/${id}/mfa/authenticator`
    })
  }

  generateApiKey ({ id, apikey_lifetime, send, comment }) {
    return this.request({
      method: 'post',
      url: `v4/users/${id}/apikey`,
      params: {
        send,
        apikey_lifetime,
        comment
      }
    })
  }

  removeApiKey ({ id, apikey }) {
    return this.request({
      method: 'delete',
      url: `v4/users/${id}/apikey/${apikey}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/users/${this._id}`
    })
  }

  async load () {
    const user = await this.request({
      method: 'get',
      url: `v4/users/${this._id}`
    })
    this.assign(user)
    this.defaultOptions()
  }

  share (data = {}) {
    return this.request({
      method: 'POST',
      url: 'applications/share',
      data
    })
  }
}

class Users extends API {
  async list (options) {
    const users = await this.paginateListing({
      method: 'get',
      url: 'v4/users',
      onProgress (users) {
        if (options.onProgress) options.onProgress(users.map(user => new User(user)))
      }
    })
    return users.map(user => new User(user))
  }

  async findOne (id, baseUrl) {
    const config = await Config()
    const user = await this.request({
      method: 'GET',
      baseURL: baseUrl ? `${config[baseUrl]}/iam` : null,
      url: `v4/users/${id}`
    })
    return new User(user)
  }

  async names () {
    const users = await this.request({
      method: 'get',
      url: 'v4/users/names'
    })
    return users.map(user => new User(user))
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new User(item)
  }
}

export default Users
export {
  User,
  Users
}
