import Vue from 'vue'

const Number = function (number, precision) {
  const lang = Vue.i18n.locale() || 'en'
  const langToString = `${lang}-${lang.toUpperCase()}`

  if (!number) {
    return 0
  }

  const options = {}
  if (precision) {
    options.minimumFractionDigits = precision
    options.maximumFractionDigits = precision
  }

  return number.toLocaleString(langToString, options)
}
export default Number
