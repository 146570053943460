const contextMenu = {
  beforeMount (el, { value }, { context }) {
    if (!value) return
    if (!value.options?.length || value.disable) return
    let iframe = false
    el.addEventListener('contextmenu', (event) => {
      const elementData = el.getBoundingClientRect()
      event.preventDefault()
      if (value.onFocus) value.onFocus.bind(context)()
      let contextMenu = el.querySelector('.context-menu')
      if (contextMenu) contextMenu.remove()

      // calculating contextMenuHeight (40 is the height of each column)
      const contextMenuHeight = (value.options.length) * 40
      // calculating trees div
      const fpTreeDiv = document.querySelector('.fp-tree .vue-recycle-scroller__item-wrapper') ? document.querySelector('.fp-tree .vue-recycle-scroller__item-wrapper').getBoundingClientRect().height + document.querySelector('.fp-tree').getBoundingClientRect().y : ''
      const advancedTreeDiv = document.querySelector('.treeview-container') ? document.querySelector('.treeview-container').getBoundingClientRect().height + document.querySelector('.treeview-container').getBoundingClientRect().y - 20 : ''
      let top = elementData.top
      if (fpTreeDiv || advancedTreeDiv) {
        if ((fpTreeDiv || advancedTreeDiv) < event.clientY + contextMenuHeight) {
          top = elementData.top + 100
        }
      }
      // detect clicking on iframe and close context menu
      window.addEventListener('blur', el.removeContextWhenClickingOnIframe.handler)
      if (document.querySelector('.dpe-code-editor iframe')) {
        document.querySelector('.dpe-code-editor iframe').addEventListener('mouseover', function () {
          iframe = true
        })
      }

      event.target.innerHTML += `<ul class="context-menu" style="left:${event.pageX - elementData.left}px;top:${event.pageY - top}px"></ul>`
      contextMenu = el.querySelector('.context-menu')
      contextMenu.innerHTML = ''

      value.options.forEach(option => {
        if (!option.if) return
        const optionNode = document.createElement('li')
        optionNode.innerHTML = `<i class="fp4 ${option.icon}"></i> <span class="${option.class}">${option.label}</span></i>`
        optionNode.className = option.class
        const disabledState = option.disabled
        if (disabledState) { optionNode.className += ' disabled' }
        optionNode.addEventListener('click', _ => {
          if (!disabledState) option.click.bind(context)()
          const contextMenu = event.target.querySelector('.context-menu')
          if (contextMenu && option.action !== 'delete') contextMenu.remove()
        })
        contextMenu.append(optionNode)
      })
    })

    const isParent = (target) => {
      if (target === el) return true
      if (target.parentNode) return isParent(target.parentNode)
      return false
    }

    el.removeContextWhenClickingOnIframe = {
      handler () {
        const contextMenu = document.querySelector('.context-menu')
        if (iframe && contextMenu) contextMenu.remove()
      }
    }

    el.windowEvents = [{
      action: 'click',
      handler (event) {
        const contextMenu = document.querySelector('.context-menu')
        if (contextMenu) {
          if (!event.target.classList.contains('delete-with-confirmation')) {
            contextMenu.remove()
          }
        }
      }
    }, {
      action: 'contextmenu',
      handler (localEvent) {
        if (isParent(localEvent.target)) return
        const contextMenu = el.querySelector('.context-menu')
        if (contextMenu) contextMenu.remove()
      }
    }]
    el.windowEvents.forEach(event => {
      window.addEventListener(event.action, event.handler)
    })
  },
  unmounted (el, { value }) {
    if (!value) return
    if (!value.options?.length || value.disable) return
    window.removeEventListener('blur', el.removeContextWhenClickingOnIframe.handler)
    el.windowEvents.forEach(event => {
      window.removeEventListener(event.action, event.handler)
    })
  }
}

export default contextMenu
