/* istanbul ignore file */
import FpStore from '@/shared/store'
import _get from 'lodash/get'
import _set from 'lodash/set'
import 'moment/locale/fr'
import 'prismjs'
import 'prismjs/components/prism-bash.js'
import 'prismjs/components/prism-python.min.js'
import 'prismjs/themes/prism-okaidia.css'
import Vue from 'vue'
// import AsyncComputed from 'vue-async-computed'
// import VueClipboard from 'vue-clipboard2'
import vuexI18n from 'vuex-i18n'
import './Acl'
import './Api'
import './FpMessageBlock'
import './VirtualScroll'
import './WebSockets'


// Vue.use(vuexI18n.plugin, FpStore)
// Vue.i18n.fallback('en')

export default {
  install (app) {
    app.use(FpStore)
    app.use(vuexI18n.plugin, FpStore)

    app.i18n.fallback('en')
    Vue.$modal = app.config.globalProperties.$modal
    // console.log('app.config.globalProperties.$modal', app.config.globalProperties)
    // console.log('app.config.globalProperties.$modal 2', Vue.$modal)
    Vue.i18n = app.config.globalProperties.$i18n
    // Vue.i18n.$t = app.config.globalProperties.$i18n.translate
    Vue.$t = app.config.globalProperties.$i18n.translate
    Vue._get = app.config.globalProperties.$_get = _get
    Vue._set = app.config.globalProperties.$_set = _set
    // native Vue.toRaw shortcut, usage : this.$toRaw(obj)
    Vue.$toRaw = app.config.globalProperties.$toRaw = Vue.toRaw
  }
}
