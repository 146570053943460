import { h } from 'vue'
import { CardComponent } from '@chargebee/chargebee-js-vue-wrapper'

export default {
  extends: CardComponent,
  // Make sur the component is running in Vue 3 mode
  // This is required to make the slot work
  compatConfig: {
    MODE: 3
  },
  render () {
    const children = this.$slots.default ? this.$slots.default() : []
    // There's a typo in the original component
    // it should be 'classes' instead of 'class'
    return h('div', { id: this.elementId, class: this.classes }, children)
  }
}
