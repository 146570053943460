import { Instance } from '@/shared/plugins/Api/STORAGE/Instances'
import Vue from 'vue'

export default {
  state: {
    instances: []
  },
  mutations: {
    STORAGE_INSTANCES_INIT (state, { instances, organizationId }) { // Not WS
      state.instances = state.instances.filter(i => organizationId !== i.organization_id && i.id !== 'shared')
      state.instances = [...state.instances, ...instances]
    },
    STORAGE_INSTANCES_UPDATE (state, data) { // Not WS (I think)
      const updatedActionIndex = state.instances.findIndex(a => data.id === a.id)
      if (updatedActionIndex !== -1) {
        state.instances[updatedActionIndex] = new Instance(data)
      } else {
        state.instances.push(new Instance(data))
      }
    },
    STORAGE_INSTANCES_CREATE (state, data) { // Not WS (I think)
      state.instances = state.instances.filter(i => data.id !== i.id)
      state.instances.push(new Instance(data))
    },
    STORAGE_INSTANCES_REMOVE (state, data) {
      state.instances = state.instances.filter(a => data.id !== a.id)
    }
  },
  actions: {
    // List instances
    async REFRESH_STORAGE_INSTANCES_BY_ORGANIZATION ({ commit }, organizationId) {
      const data = await Vue.api.STORAGE.instances.listByOrganization(organizationId)

      data.instances.unshift({
        id: 'shared',
        organization_id: '',
        engine: 'sandbox',
        provider: '',
        region: '',
        display_region: '',
        fpu_type: '',
        fpu: '',
        storage: '',
        display_name: 'Sandbox',
        description: 'Data Platform Engine',
        type: 'engine',
        status: 'Available',
        forepaas: true,
        tags: []
      })
      commit('STORAGE_INSTANCES_INIT', data)
    }
  },
  getters: {
    // Return all instances
    STORAGE_INSTANCES (state) {
      return state.instances
    },
    // Return instances for an organization
    STORAGE_INSTANCES_BY_ORGANIZATION_ID (state) {
      return (organizationId) => {
        return state.instances.filter(i => i.organization_id === organizationId || i.id === 'shared') || []
      }
    },
    // Return instance by id
    STORAGE_INSTANCES_BY_ID (state) {
      return id => state.instances.find(i => i.id === id)
    }
  }
}
