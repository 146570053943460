/* istanbul ignore file */
// import Vue from 'vue'

import FpAnalytics from './FpAnalytics'

export default {
  async init (router, category = 'FPUI', app) {
    const analytics = new FpAnalytics(router, category, app)
    app.use(analytics)
    const options = await analytics.consent()
    analytics.load(options)
  }
}
