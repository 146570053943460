import interact from 'interactjs'

const droppable = {
  beforeMount (el, { value }) {
    const element = interact(el)
    element.dropzone({
      ondrop: async (event) => {
        value(event.relatedTarget.value)
      }
    })
  }
}

export default droppable
