/* istanbul ignore file */
import Toaster from './Toaster'
import './style.less'
import Vue from 'vue'

export default {
  install (app) {
    window.fpuiMessageBlockRemove = Toaster.remove
    Vue.fpuiMessageBlock = app.config.globalProperties.$fpuiMessageBlock = Toaster
  }
}
