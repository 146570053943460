const Duration = function (durationInSecond, decimal = 0, separators = {}) {
  separators.hours = separators.hours || ':'
  separators.minutes = separators.minutes || ':'
  if (isNaN(durationInSecond) || !durationInSecond) return '0'
  const seconds = (durationInSecond % 60)
  let minutes = Math.floor(durationInSecond / 60)
  const hours = Math.floor(minutes / 60)
  minutes %= 60
  if (hours) return `${String(hours).padStart(2, '0')}${separators.hours}${String(minutes).padStart(2, '0')}${separators.minutes}${String(seconds.toFixed(0)).padStart(2, '0')}`
  if (minutes) return `${String(minutes).padStart(2, '0')}${separators.minutes}${String(seconds.toFixed(0)).padStart(2, '0')}`
  return `${String(seconds.toFixed(decimal)).padStart(2, '0')} s`
}

export default Duration
