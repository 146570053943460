<template lang="html">
  <div
    v-observe-visibility="handleVisibilityChange"
    class="vue-recycle-scroller"
    :class="{
      ready,
      'page-mode': pageMode,
      [`direction-${direction}`]: true,
    }"
    @scroll.passive="handleScroll"
  >
    <div
      v-if="$slots.before"
      ref="before"
      class="vue-recycle-scroller__slot"
    >
      <slot
        name="before"
      />
    </div>

    <component
      :is="listTag"
      ref="wrapper"
      :style="{ [direction === 'vertical' ? 'minHeight' : 'minWidth']: minHeight + 'px' }"
      class="vue-recycle-scroller__item-wrapper"
      :class="listClass"
    >
      <div
        v-for="view of pool"
        ref="items"
        :key="view.nr.id"
        :view="view"
        :item-tag="itemTag"
        :style="ready
          ? [
            (disableTransform
              ? { [direction === 'vertical' ? 'top' : 'left'] : `${view.position}px`, willChange: 'unset' }
              : { transform: `translate${direction === 'vertical' ? 'Y' : 'X'}(${view.position}px) translate${direction === 'vertical' ? 'X' : 'Y'}(${view.offset}px)` }),
            {
              width: gridItems ? `${direction === 'vertical' ? itemSecondarySize || itemSize : itemSize}px` : undefined,
              height: gridItems ? `${direction === 'horizontal' ? itemSecondarySize || itemSize : itemSize}px` : undefined,
            }
          ]
          : null"
        class="vue-recycle-scroller__item-view"
        :class="[
          realItemClasses(view),
          {
            hover: !skipHover && hoverKey === view.nr.key
          },
        ]"
        v-on="skipHover ? {} : {
          mouseenter: () => { hoverKey = view.nr.key },
          mouseleave: () => { hoverKey = null },
        }"
      >
        <slot
          :item="view.item"
          :index="view.nr.index"
          :active="view.nr.used"
        />
      </div>

      <slot
        name="empty"
      />
    </component>

    <div
      v-if="$slots.after"
      ref="after"
      class="vue-recycle-scroller__slot"
    >
      <slot
        name="after"
      />
    </div>

    <ResizeObserver @notify="handleResize" />
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

const config = {
  itemsLimit: 1000
}

// Extend the RecycleScroller component
export default {
  extends: RecycleScroller,
  props: {
    ...RecycleScroller.props,
    itemClasses: {
      required: false,
      default: () => {
        return []
      },
      type: Function
    }
  },
  computed: {
    minHeight () {
      // Use to define a min hieght and get enought place to display the context menu
      return this.totalSize < 250 ? 250 : this.totalSize
    }
  },
  methods: {
    ...RecycleScroller.methods,
    realItemClasses ({ item, nr }) {
      const cls = this.itemClasses(item)
      if (nr.key === this.hoverKey) cls.push('hover')
      return cls.join(' ')
    },
    updateVisibleItems (checkItem, checkPositionDiff = false) {
      const itemSize = this.itemSize
      const gridItems = this.gridItems || 1
      const itemSecondarySize = this.itemSecondarySize || itemSize
      const minItemSize = this.$_computedMinItemSize
      const typeField = this.typeField
      const keyField = this.simpleArray ? null : this.keyField
      const items = this.items
      const count = items.length
      const sizes = this.sizes
      const views = this.$_views
      const unusedViews = this.$_unusedViews
      const pool = this.pool
      const itemIndexByKey = this.itemIndexByKey
      let startIndex, endIndex
      let totalSize
      let visibleStartIndex, visibleEndIndex

      if (!count) {
        startIndex = endIndex = visibleStartIndex = visibleEndIndex = totalSize = 0
      } else if (this.$_prerender) {
        startIndex = visibleStartIndex = 0
        endIndex = visibleEndIndex = Math.min(this.prerender, items.length)
        totalSize = null
      } else {
        const scroll = this.getScroll()

        // Skip update if use hasn't scrolled enough
        if (checkPositionDiff) {
          let positionDiff = scroll.start - this.$_lastUpdateScrollPosition
          if (positionDiff < 0) positionDiff = -positionDiff
          if ((itemSize === null && positionDiff < minItemSize) || positionDiff < itemSize) {
            return {
              continuous: true
            }
          }
        }
        this.$_lastUpdateScrollPosition = scroll.start

        const buffer = this.buffer
        scroll.start -= buffer
        scroll.end += buffer

        // account for leading slot
        let beforeSize = 0
        if (this.$refs.before) {
          beforeSize = this.$refs.before.scrollHeight
          scroll.start -= beforeSize
        }

        // account for trailing slot
        if (this.$refs.after) {
          const afterSize = this.$refs.after.scrollHeight
          scroll.end += afterSize
        }

        // Variable size mode
        if (itemSize === null) {
          let h
          let a = 0
          let b = count - 1
          let i = ~~(count / 2)
          let oldI

          // Searching for startIndex
          do {
            oldI = i
            h = sizes[i].accumulator
            if (h < scroll.start) {
              a = i
            } else if (i < count - 1 && sizes[i + 1].accumulator > scroll.start) {
              b = i
            }
            i = ~~((a + b) / 2)
          } while (i !== oldI)
          i < 0 && (i = 0)
          startIndex = i

          // For container style
          totalSize = sizes[count - 1].accumulator

          // Searching for endIndex
          for (endIndex = i; endIndex < count && sizes[endIndex].accumulator < scroll.end; endIndex++);
          if (endIndex === -1) {
            endIndex = items.length - 1
          } else {
            endIndex++
            // Bounds
            endIndex > count && (endIndex = count)
          }

          // search visible startIndex
          for (visibleStartIndex = startIndex; visibleStartIndex < count && (beforeSize + sizes[visibleStartIndex].accumulator) < scroll.start; visibleStartIndex++);

          // search visible endIndex
          for (visibleEndIndex = visibleStartIndex; visibleEndIndex < count && (beforeSize + sizes[visibleEndIndex].accumulator) < scroll.end; visibleEndIndex++);
        } else {
          // Fixed size mode
          startIndex = ~~(scroll.start / itemSize * gridItems)
          const remainer = startIndex % gridItems
          startIndex -= remainer
          endIndex = Math.ceil(scroll.end / itemSize * gridItems)
          visibleStartIndex = Math.max(0, Math.floor((scroll.start - beforeSize) / itemSize * gridItems))
          visibleEndIndex = Math.floor((scroll.end - beforeSize) / itemSize * gridItems)

          // Bounds
          startIndex < 0 && (startIndex = 0)
          endIndex > count && (endIndex = count)
          visibleStartIndex < 0 && (visibleStartIndex = 0)
          visibleEndIndex > count && (visibleEndIndex = count)

          totalSize = Math.ceil(count / gridItems) * itemSize
        }
      }

      if (endIndex - startIndex > config.itemsLimit) {
        this.itemsLimitError()
      }

      this.totalSize = totalSize

      let view

      const continuous = startIndex <= this.$_endIndex && endIndex >= this.$_startIndex

      // Unuse views that are no longer visible
      if (continuous) {
        for (let i = 0, l = pool.length; i < l; i++) {
          view = pool[i]
          if (view.nr.used) {
            // Update view item index
            if (checkItem) {
              view.nr.index = itemIndexByKey[view.item[keyField]]
            }

            // Check if index is still in visible range
            if (
              view.nr.index == null ||
              view.nr.index < startIndex ||
              view.nr.index >= endIndex
            ) {
              this.unuseView(view)
            }
          }
        }
      }

      const unusedIndex = continuous ? null : new Map()

      let item, type
      let v
      for (let i = startIndex; i < endIndex; i++) {
        item = items[i]
        const key = keyField ? item[keyField] : item
        if (key == null) {
          throw new Error(`Key is ${key} on item (keyField is '${keyField}')`)
        }
        view = views.get(key)

        if (!itemSize && !sizes[i].size) {
          if (view) this.unuseView(view)
          continue
        }

        type = item[typeField]

        let unusedPool = unusedViews.get(type)
        let newlyUsedView = false

        // No view assigned to item
        if (!view) {
          if (continuous) {
            // Reuse existing view
            if (unusedPool && unusedPool.length) {
              view = unusedPool.pop()
            } else {
              view = this.addView(pool, i, item, key, type)
            }
          } else {
            // Use existing view
            // We don't care if they are already used
            // because we are not in continous scrolling
            v = unusedIndex.get(type) || 0

            if (!unusedPool || v >= unusedPool.length) {
              view = this.addView(pool, i, item, key, type)
              this.unuseView(view, true)
              unusedPool = unusedViews.get(type)
            }

            view = unusedPool[v]
            unusedIndex.set(type, v + 1)
          }

          // Assign view to item
          views.delete(view.nr.key)
          view.nr.used = true
          view.nr.index = i
          view.nr.key = key
          view.nr.type = type
          views.set(key, view)

          newlyUsedView = true
        } else {
          // View already assigned to item
          if (!view.nr.used) {
            view.nr.used = true
            newlyUsedView = true
            if (unusedPool) {
              const index = unusedPool.indexOf(view)
              if (index !== -1) unusedPool.splice(index, 1)
            }
          }
        }

        // Always set item in case it's a new object with the same key
        view.item = item

        if (newlyUsedView) {
          if (i === items.length - 1) this.$emit('scroll-end')
          if (i === 0) this.$emit('scroll-start')
        }

        // Update position
        if (itemSize === null) {
          view.position = sizes[i - 1].accumulator
          view.offset = 0
        } else {
          view.position = Math.floor(i / gridItems) * itemSize
          view.offset = (i % gridItems) * itemSecondarySize
        }
      }

      this.$_startIndex = startIndex
      this.$_endIndex = endIndex

      if (this.emitUpdate) this.$emit('update', startIndex, endIndex, visibleStartIndex, visibleEndIndex)

      // After the user has finished scrolling
      // Sort views so text selection is correct
      // SPEC HERE, WE ARE DISABLE THE sortViews cause it blur an eventual focus on an inp
      // clearTimeout(this.$_sortTimer);
      // this.$_sortTimer = setTimeout(this.sortViews, this.updateInterval + 300);

      return {
        continuous
      }
    }
  }
}
</script>
