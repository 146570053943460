import mitt from 'mitt'

class EventBus {
  constructor () {
    this.bus = mitt()
  }

  $on (event, callback) {
    this.bus.on(event, callback)
  }

  $once (event, callback) {
    this.bus.once(event, callback)
  }

  $emit (event, ...args) {
    // You can only pass 1 argument other than event name for mitt so if the length of args is more than 1, we need to make it array
    if (args && args.length > 1) this.bus.emit(event, [...args])
    else this.bus.emit(event, ...args)
  }

  $off (event, ...args) {
    this.bus.off(event, ...args)
  }

  $destroy () {
    this.bus.all.clear()
  }
}

export default EventBus
